:root {
  --brandColor: #fd7500;
}

html,
body {
  padding: 0;
  margin: 0;
  font-size: 16px;
  background-color: #f5f5f5;
  position: relative;
  z-index: 0;
  background-image: url('/top-triangle-element.svg'), url('/bottom-triangle-element.svg');
  background-position: top right, bottom left;
  background-repeat: no-repeat;
  background-size: 60vw, 70vw;
}
@media only screen and (max-width: 600px) {
  html {
    font-size: 14px;
  }
}

main {
  font-family: var(--font-nunito-sans), sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: var(--font-montserrat), serif;
}

h1 {
  font-size: 2.4rem;
  font-weight: 600;
}

h2 {
  font-size: 2.1rem;
  font-weight: 600;
}

h3 {
  font-size: 1.8rem;
  font-weight: 500;
}

h4 {
  font-size: 1.5rem;
  font-weight: 400;
}

h5 {
  font-size: 1.2rem;
  font-weight: 300;
}

a {
  text-decoration: none;
  font-weight: 400;
  color: var(--brandColor);
  cursor: pointer;
}

li {
  padding-bottom: 5px;
}

* {
  box-sizing: border-box;
}

.line-highlight {
  background: linear-gradient(
    to right,
    hsla(24, 20%, 50%, 0.2) 80%,
    hsla(24, 20%, 50%, 0)
  ) !important;
}

#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: var(--brandColor);

  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;

  width: 100%;
  height: 4px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px var(--brandColor), 0 0 5px var(--brandColor);
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}
